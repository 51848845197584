import React from 'react'
import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const OuterSideBarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fbeec1;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
  color: #0d0d0d;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  border: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const OuterSideBarWrapper = styled.div`
  color: #0d0d0d;
`;

export const OuterSideBarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const OuterSideBarLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #0d0d0d;
  cursor: pointer;

  &:hover {
    color: black;
    transition: 0.2s ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const OuterSideBarRoute = styled(LinkR)`
  border-radius: 50px;
  background: #bc986a;
  white-space: nowrap;
  padding: 16px 64px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;


const OuterSideBar = ({ toggle, isOpen }) => {
    return (
        <OuterSideBarContainer isOpen={isOpen} onClick={toggle}>
          <Icon onClick={toggle}>
            <CloseIcon />
          </Icon>
            <OuterSideBarWrapper>
              <OuterSideBarMenu>
                <OuterSideBarLink to='/' onClick={toggle}>
                  Home
                </OuterSideBarLink>
                <OuterSideBarLink to='/cq' onClick={toggle}>
                  FAQ
                </OuterSideBarLink>
                <OuterSideBarLink to='/service' onClick={toggle}>
                  Services
                </OuterSideBarLink>
              </OuterSideBarMenu>
            </OuterSideBarWrapper>
        </OuterSideBarContainer>
    )
}

export default OuterSideBar
