import React, { useState } from 'react'
import Sunset from '../../images/moonmtn.jpg'
import { HeroContainer, HeroBg, VideoBg, HeroP, HeroH1, HeroContent, HeroBtnWrapper, ArrowForward, ArrowRight } from './Hero'
import { Button } from '../ButtonElement.js'
import './HeroSection.css'
import { Modal } from '../../components/Modal/Modal.js';

const HeroSection = ({ submitSuccess, setSubmitSuccess }) => {
  const [hover, setHover] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(prev => !prev)
  }

  const onHover = () => {
    setHover(!hover)
  }
    return (
      <>
        <HeroContainer id='home'>
          <HeroBg>
            <VideoBg src={Sunset} />
          </HeroBg>
          <HeroContent>
            <HeroH1>Soar Counseling Services Inc.</HeroH1>
            <HeroP>Cody, Wyoming</HeroP>
            <HeroBtnWrapper>
              <Button
              to='home'
              onClick={openModal}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              >
                Contact {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>
            </HeroBtnWrapper>
            <Modal submitSuccess={submitSuccess} setSubmitSuccess={setSubmitSuccess} showModal={showModal} setShowModal={setShowModal} />
          </HeroContent>
        </HeroContainer>
        </>
    )
}

export default HeroSection
